import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
//import { StepsModule } from 'primeng/steps';
//import { ToastModule } from 'primeng/toast';
//import { MenuItem, MessageService } from 'primeng/api';

//import { ButtonModule } from 'primeng/button';
@Component({
    selector: 'app-sellform',

    templateUrl: './sellform.component.html',
    styleUrl: './sellform.component.scss'
})
export class SellformComponent {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    vehicleForm: FormGroup;
    sellForm: FormGroup;
    buyForm: FormGroup;
    RegisterForm: FormGroup;
    signForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementNo: any
    Sdata: any
    isUpdateMode: boolean = false;
    // items: MenuItem[] | undefined;
    activeIndex: number = 0;


    active: number = 0;

    currentStep: number = 1;
    ImageData: any;
    image: any;
    EImage: any;
    Exteriorimg: any;
    ImageArray: any;
    maxImages: any;
    BuyerSignature: any;
    WitnessSignature: any;
    VehiclePhoto: any;

    SellerIMG: any; BuyyerIMG: any; WitnessIMG1: any; WitnessIMG2: any;



    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute,
        //  public messageService: MessageService
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.sellForm = this.formBuilder.group({
            // Assuming loginDetails is the data object containing the logged-in user's information

            SellerName: [this.loginDet[0].Name || '', [Validators.required]],
            SellerFatherName: [this.loginDet[0].FatherName || '', [Validators.required]],
            SellerAge: [this.loginDet[0].Age || '', [Validators.required]],
            SellerOccupation: ['', [Validators.required]],
            SellerHNo: [this.loginDet[0].HNo || '', [Validators.required]],
            SellerStreet: [this.loginDet[0].Street || '', [Validators.required]],
            SellerColony: [this.loginDet[0].Colony || '', [Validators.required]],
            SellerMandal: [this.loginDet[0].Mandal || '', [Validators.required]],
            SellerState: [this.loginDet[0].StateName || '', [Validators.required]],
            SellerPincode: [this.loginDet[0].Pincode || '', [Validators.required]],
            SellerMobile: [this.loginDet[0].Mobile || '', [Validators.required]],


        });
        this.buyForm = this.formBuilder.group({
            BuyerName: ['', [Validators.required]],
            BuyerFatherName: ['', [Validators.required]],
            BuyerAge: ['', [Validators.required]],
            BuyerOccupation: ['', [Validators.required]],
            BuyerHNo: ['', [Validators.required]],
            BuyerStreet: ['', [Validators.required]],
            BuyerColony: ['', [Validators.required]],
            BuyerMandal: ['', [Validators.required]],
            BuyerState: ['', [Validators.required]],
            BuyerPincode: ['', [Validators.required]],
            BuyerMobile: ['', [Validators.required]],
        });
        this.vehicleForm = this.formBuilder.group({
            VehicleRegistrationNo: ['', [Validators.required]],
            VehicleChassisNo: ['', [Validators.required]],
            VehicleEngineNo: ['', [Validators.required]],
            VehicleType: ['', [Validators.required]],
            ///   TransportType: ['', [Validators.required]],
            VehicleModel: ['', [Validators.required]],
            VehicleFuelType: ['', [Validators.required]],
            FitnessValidity: ['', [Validators.required]],
            PermitNumber: ['', [Validators.required]],
            PermitValidity: ['', [Validators.required]],
            MeterSealValidity: ['', [Validators.required]],
            CNGLPGValidity: ['', [Validators.required]],
            EVBatteryValidity: ['', [Validators.required]],
            TaxValidity: ['', [Validators.required]],
            RCValidity: ['', [Validators.required]],
            PaymentDueDate: ['', [Validators.required]],
            VehiclePrice: ['', [Validators.required]],
            Agreetopay: ['', [Validators.required]],
            PaidAmount: ['', [Validators.required]],
            RemainingAmount: ['', [Validators.required]],
            FinancierName: ['', [Validators.required]],
            FinancierAddress: ['', [Validators.required]],
            TransportType: ['', [Validators.required]],
            VehicleMake: ['', [Validators.required]],
            Docstore: ['', [Validators.required]],
            InsuranceValidity: ['', [Validators.required]],
            VehicleMakeMonth: ['', [Validators.required]],



            SellerIMG: ['', [Validators.required]],
            BuyyerIMG: ['', [Validators.required]],
            WitnessIMG1: ['', [Validators.required]],
            WitnessIMG2: ['', [Validators.required]],

            SellerSignature: ['', [Validators.required]],
            BuyerSignature: ['', [Validators.required]],
            WitnessSignature: ['', [Validators.required]],
            VehiclePhoto: ['', [Validators.required]],
        });

        this.aggrementNo = localStorage.getItem("AgreementNo");
    }

    ngOnInit() {

    }

    onActiveIndexChange(event: number) {
        this.activeIndex = event;
    }
    goToNext() {

        if (this.currentStep === 1) {
            // Validate that all fields in the buyer form are filled
            if (this.sellForm.invalid) {
                alert('Please fill in all required fields.');
                return; // Stop if validation fails
            }

            // Save buyer details to localStorage
            const sellerData = this.sellForm.value;  // Assuming buyForm is your form for buyer details
            localStorage.setItem('seller', JSON.stringify(sellerData));
            // console.log('Buyer details saved:', buyerData);
        }

        if (this.currentStep === 2) {
            // Validate that all fields in the buyer form are filled
            if (this.buyForm.invalid) {
                alert('Please fill in all required fields.');
                return; // Stop if validation fails
            }

            // Save buyer details to localStorage
            const buyerData = this.buyForm.value;  // Assuming buyForm is your form for buyer details
            localStorage.setItem('buyer', JSON.stringify(buyerData));
            // console.log('Buyer details saved:', buyerData);
        }
        if (this.currentStep === 3) {
            // Save buyer details to localStorage
            const vehicleData = this.vehicleForm.value;  // Assuming buyerForm is your form for buyer details
            localStorage.setItem('Vehicle', JSON.stringify(vehicleData));
            //  console.log('Buyer details saved:', buyerData);
        } if (this.currentStep === 4) {
            // Save buyer details to localStorage
            const vehicleData = this.vehicleForm.value;  // Assuming buyerForm is your form for buyer details
            localStorage.setItem('Vehicle', JSON.stringify(vehicleData));
            //  console.log('Buyer details saved:', buyerData);
        }
        if (this.currentStep < 5) {
            this.currentStep++;
        }
    }
    page() {
        this.router.navigate(['/Sell1form'])
    }

    goToPrevious() {
        if (this.currentStep > 1) {
            this.currentStep--;
        }
    }



    storeSellerData() {
        const sellerData = this.RegisterForm.value;
        localStorage.setItem('buyer', JSON.stringify(sellerData));
    }

    getRegistration(formValue: any) {

        // Store the data in localStorage
        this.storeSellerData();
    }

    updateRegistration(formValue: any) {

        this.storeSellerData();
    }
    removeImage1(index: number): void {
        this.Exteriorimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage(index: number): void {
        this.BuyerSignature.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage2(index: number): void {
        this.WitnessSignature.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage3(index: number): void {
        this.VehiclePhoto.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage4(index: number): void {
        this.SellerIMG.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage5(index: number): void {
        this.BuyyerIMG.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage6(index: number): void {
        this.WitnessIMG1.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage7(index: number): void {
        this.WitnessIMG2.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }



    detectFiles(event): void {
        let file = event.target.files[0]; // Only take the first file
        debugger

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                debugger
                this.Exteriorimg = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_exterior', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles1(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.BuyerSignature = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_buyer', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles2(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.WitnessSignature = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_witness', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles3(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.VehiclePhoto = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_vehicle', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }


    detectFiles4(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.SellerIMG = [e.target.result]; // Replace with the new image
                localStorage.setItem('Seller_photo', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }


    detectFiles5(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.BuyyerIMG = [e.target.result]; // Replace with the new image
                localStorage.setItem('Buyyer_photo', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles6(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.WitnessIMG1 = [e.target.result]; // Replace with the new image
                localStorage.setItem('Witness_photo1', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles7(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.WitnessIMG2 = [e.target.result]; // Replace with the new image
                localStorage.setItem('Witness_photo2', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }




    //This is for Uploading Multiple Images
    fileChange(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                if (data != null) {
                    this.ImageData = data;
                    this.image = data;
                    this.EImage.push({ ExteriorImages: this.ImageData })
                }
            });
        }

    }



    submitVehicleDetails(formValue: any) {
        console.log('Vehicle Form Submitted:', formValue);
        // Additional logic for vehicle form submission
    }

    updateVehicleDetails(formValue: any) {
        console.log('Vehicle Form Updated:', formValue);
        // Additional logic for vehicle form update
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    onDateChange(event: any) {
        const formattedDate = this.formatDate(event.target.value);
        this.RegisterForm.get('AuctionedDate')?.setValue(formattedDate);
    }
    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }





    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }

    // Method to fetch states from the server
    getstates() {
        debugger;
        var url = "api/FinancierLaw/FA_GetStates";
        this.generalService.Getdata(url).then(
            data => {
                debugger;
                this.Statedata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a state is selected in the dropdown
    onStateSelection(value: string) {
        debugger;
        this.StateId = value;  // Store the selected state ID
        this.Getdistricts();  // Fetch districts based on the selected state
    }

    // Method to fetch districts based on the selected state ID
    Getdistricts() {
        debugger;
        if (!this.StateId) {
            this.generalService.ShowAlert('ERROR', 'State ID is missing. Please select a state.', 'error');
            return;
        }

        var UploadFile = new FormData();
        UploadFile.append("StateId", this.StateId);  // Append the state ID to the form data

        var url = "api/FinancierLaw/FA_GetDistricts";
        this.generalService.Postdata1(url, UploadFile).subscribe(
            data => {
                debugger;
                this.DistrictName = data;  // Store the fetched district data
                if (!this.DistrictName || this.DistrictName.length === 0) {
                    this.generalService.ShowAlert('ERROR', 'No districts found for the selected state.', 'error');
                }
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a district is selected in the dropdown
    onDistrictSelection(value: string) {
        debugger;
        this.selectedDistrictName = value;  // Store the selected district name
    }
    Getsearch() {
        debugger;
        const vehicleRegistrationNo = this.vehicleForm.get('VehicleRegistrationNo').value;


        var UploadFile = new FormData();
        UploadFile.append("Param1", vehicleRegistrationNo);  // Append the state ID to the form data

        var url = "api/FinancierLaw/Get_secondformlist";
        this.generalService.Postdata1(url, UploadFile).subscribe(
            data => {
                debugger;
                this.Sdata = data;

                const formatDate = (dateString: string) => {
                    const date = new Date(dateString);
                    return date.toISOString().split('T')[0];  // Convert to yyyy-MM-dd format
                };
                this.vehicleForm.patchValue({
                    TransportType: this.Sdata[0].TransportType,
                    VehicleRegistrationNo: this.Sdata[0].VehicleRegistrationNo,
                    VehicleChassisNo: this.Sdata[0].VehicleChassisNo,
                    VehicleEngineNo: this.Sdata[0].VehicleEngineNo,
                    VehicleType: this.Sdata[0].VehicleType,
                    VehicleMakeMonth: this.Sdata[0].VehicleMakeMonth,
                    VehicleMake: this.Sdata[0].VehicleMake,
                    VehicleModel: this.Sdata[0].VehicleModel,
                    VehicleFuelType: this.Sdata[0].VehicleFuelType,
                    CNGLPGValidity: this.Sdata[0].CNGLPGValidity ? formatDate(this.Sdata[0].CNGLPGValidity) : '',
                    EVBatteryValidity: this.Sdata[0].EVBatteryValidity ? formatDate(this.Sdata[0].EVBatteryValidity) : '',
                    FitnessValidity: this.Sdata[0].FitnessValidity ? formatDate(this.Sdata[0].FitnessValidity) : '',
                    PermitValidity: this.Sdata[0].PermitValidity ? formatDate(this.Sdata[0].PermitValidity) : '',
                    MeterSealValidity: this.Sdata[0].MeterSealValidity ? formatDate(this.Sdata[0].MeterSealValidity) : '',
                    TaxValidity: this.Sdata[0].TaxValidity ? formatDate(this.Sdata[0].TaxValidity) : '',
                    InsuranceValidity: this.Sdata[0].InsuranceValidity ? formatDate(this.Sdata[0].InsuranceValidity) : '',
                    RCValidity: this.Sdata[0].RCValidity ? formatDate(this.Sdata[0].RCValidity) : '',

                    PermitNumber: this.Sdata[0].PermitNumber,

                });
            },
            err => {
                //  this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

}


