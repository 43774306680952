<!-- 4th Form -->
<div id="pdf-content" class="mt-1 ptb-70">
    <div class="container pt-5 mt-1">
        <div class="topheader">
            <h4 style="font-size: 25px; text-align:center; font-weight:bold; margin-bottom:10px;font-family: Arial Black; text-decoration: none;">
                 {{loginDet[0].CompanyName | uppercase}} 
            </h4>
            <p style="text-align:center;font-weight:600 ">
                {{loginDet[0].CompanyAddress}} ,   {{loginDet[0].Mobile}} 
            </p>
        </div><br />
        <div class="row">
            <div class="col-lg-12 text-center">

                <h1>VEHICLE SALE RECEIPT/ AGREEMENT</h1>
                <p class="float-end">Date: <b>{{currentDate}}</b></p><br />

            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="buyer-details">
                    <h3><b>Seller Details:</b></h3>
                    <div class="row">
                        <div class="col-lg-12 lh_1p8">
                            <b class="text-red">{{seller.SellerName}}</b> S/o
                            <b class="text-red">{{seller.SellerFatherName}}</b>,
                            <!--<b>{{seller.SellerAge}}yrs </b>,--> {{seller.SellerAge}},   <b class="text-red">{{loginDet[0].CompanyName}}</b>,
                           <b class="text-red">{{loginDet[0].CompanyAddress}}</b>, <b class="text-red"> {{loginDet[0].Mobile}}</b>,
                            <b class="text-red"> {{seller.SellerOccupation}} ,

                            {{seller.SellerHNo}}, {{seller.SellerStreet}}, {{seller.SellerColony}}, {{seller.SellerMandal}}, {{seller.SellerState}}, {{seller.SellerPincode}} </b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="seller-details mt-2">
                    <h3><b>Buyer Details:</b></h3>
                    <div class="row">
                        <div class="col-lg-12 ">
                            <b class="text-red">{{buyer.BuyerName}}</b> S/o
                            <b class="text-red">{{buyer.BuyerFatherName}} </b>,
                           <b class="text-red">{{buyer.BuyerAge}}yrs </b>,
                            <b class="text-red"> {{buyer.BuyerOccupation}},
                            {{buyer.BuyerHNo}},  {{buyer.BuyerStreet}}, {{buyer.BuyerColony}}, {{buyer.BuyerMandal}}, {{buyer.BuyerState}}, {{buyer.buyerPincode}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <br />
        <div class="row">
            <div class="col-lg-12">
                <div class="vehicle-details">
                    <h3>Vehicle Details: Transport / Non-Transport: {{Vehicle.TransportType}}</h3>

                    <br />
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table">
                                <tbody>
                                    <tr *ngIf="Vehicle.VehicleRegistrationNo || Vehicle.VehicleChassisNo || Vehicle.VehicleEngineNo">
                                        <td class="border" *ngIf="Vehicle.VehicleRegistrationNo"><div>Registration No</div></td>
                                        <td class="border" *ngIf="Vehicle.VehicleRegistrationNo"><b class="text-red">{{Vehicle.VehicleRegistrationNo}}</b></td>
                                       
                                        <td class="border" *ngIf="Vehicle.VehicleChassisNo">Chassis No</td>
                                        <td class="border" *ngIf="Vehicle.VehicleChassisNo"><b class="text-red">{{Vehicle.VehicleChassisNo}}</b></td>
                                     
                                        <td class="border" *ngIf="Vehicle.VehicleEngineNo">Engine No</td>
                                        <td class="border" *ngIf="Vehicle.VehicleEngineNo"><b class="text-red">{{Vehicle.VehicleEngineNo}}</b></td>
                                    </tr>

                                    <tr *ngIf="Vehicle.VehicleType || Vehicle.VehicleModel || Vehicle.VehicleFuelType">
                                        <td class="border" *ngIf="Vehicle.VehicleType">Class of Vehicle</td>
                                        <td class="border" *ngIf="Vehicle.VehicleType"><b class="text-red">{{Vehicle.VehicleType}}</b></td>
                                        <td class="border" *ngIf="Vehicle.VehicleTypes">Vehicle Name</td>
                                        <td class="border" *ngIf="Vehicle.VehicleTypes"><b class="text-red">{{Vehicle.VehicleMakeMonth}}</b></td>
                                        
                                        <td class="border" *ngIf="Vehicle.VehicleModel">MODEL</td>
                                        <td class="border" *ngIf="Vehicle.VehicleModel"><b class="text-red">{{Vehicle.VehicleModel}}</b></td>
                                      
                                        <td class="border" *ngIf="Vehicle.VehicleMake">Manufacturer Name</td>
                                        <td class="border" *ngIf="Vehicle.VehicleMake"><b class="text-red">{{Vehicle.VehicleMake}}</b></td>

                                    </tr>

                                    <tr *ngIf="Vehicle.FitnessValidity || Vehicle.PermitNumber || Vehicle.PermitValidity">
                                        <td class="border" *ngIf="Vehicle.FitnessValidity">FITNESS VALIDITY</td>
                                        <td class="border" *ngIf="Vehicle.FitnessValidity"><b class="text-red">{{Vehicle.FitnessValidity | date:'dd-MM-yyyy'}}</b></td>
                                      
                                        <td class="border" *ngIf="Vehicle.PermitNumber">PERMIT No</td>
                                        <td class="border" *ngIf="Vehicle.PermitNumber"><b class="text-red">{{Vehicle.PermitNumber}}</b></td>
                                      
                                        <td class="border" *ngIf="Vehicle.PermitValidity">Permit Validity</td>
                                        <td class="border" *ngIf="Vehicle.PermitValidity"><b class="text-red">{{Vehicle.PermitValidity | date:'dd-MM-yyyy'}}</b></td>
                                    </tr>

                                    <tr *ngIf="Vehicle.RCValidity || Vehicle.MeterSealValidity || Vehicle.CNGLPGValidity">
                                        <td class="border" *ngIf="Vehicle.VehicleFuelType">FUEL</td>
                                        <td class="border" *ngIf="Vehicle.VehicleFuelType"><b class="text-red">{{Vehicle.VehicleFuelType}}</b></td>
                                       
                                        <td class="border" *ngIf="Vehicle.RCValidity">RC Validity</td>
                                        <td class="border" *ngIf="Vehicle.RCValidity"><b class="text-red">{{Vehicle.RCValidity | date:'dd-MM-yyyy'}}</b></td>
                                       
                                        <td class="border" *ngIf="Vehicle.MeterSealValidity">METER SEAL VALIDITY</td>
                                        <td class="border" *ngIf="Vehicle.MeterSealValidity"><b class="text-red">{{Vehicle.MeterSealValidity | date:'dd-MM-yyyy'}}</b></td>
                                    </tr>

                                    <tr *ngIf="Vehicle.EVBatteryValidity || Vehicle.TaxValidity || Vehicle.CNGLPGValidity || Vehicle.InsuranceValidity">
                                        <td class="border" *ngIf="Vehicle.CNGLPGValidity">CNG/LPG TANK VALIDITY</td>
                                        <td class="border" *ngIf="Vehicle.CNGLPGValidity"><b class="text-red">{{Vehicle.CNGLPGValidity | date:'dd-MM-yyyy'}}</b></td>
                                      
                                        <td class="border" *ngIf="Vehicle.EVBatteryValidity">E.V. BATTERY WARRANTY</td>
                                        <td class="border" *ngIf="Vehicle.EVBatteryValidity"><b class="text-red">{{Vehicle.EVBatteryValidity | date:'dd-MM-yyyy'}}</b></td>
                                        
                                        <td class="border" *ngIf="Vehicle.TaxValidity">TAX VALIDITY</td>
                                        <td class="border" *ngIf="Vehicle.TaxValidity"><b class="text-red">{{Vehicle.TaxValidity | date:'dd-MM-yyyy'}}</b></td>
                                        <td class="border" *ngIf="Vehicle.InsuranceValidity">INSURANCE VALIDITY</td>
                                        <td class="border" *ngIf="Vehicle.InsuranceValidity"><b class="text-red">{{Vehicle.InsuranceValidity | date:'dd-MM-yyyy'}}</b></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>


                <div class="sale-terms mt-1">
                    <h3>Sale Terms:</h3>
                    <p>
                        1. The Seller agrees to sell the vehicle to the Buyer for <b class="text-red">Rs.{{Vehicle.VehiclePrice}}/-</b>.<br />
                        2. The Buyer acknowledges that the vehicle is being sold in its current condition.<br />
                        3. The Seller warrants that the vehicle is free from any finance or loan obligations / with finance.<br />
                        4. The Seller warrants that the vehicle is free from any RTA, police, traffic costs, or civil and criminal cases.<br />
                        5. Vehicles once sold will not be taken back or exchanged, and papers validation must be done in 30 days.<br />
                        6. No guarantee on sale of old vehicle parts and repairs.<br />
                        7. Transfer of ownership should be done by the purchaser within 30 days.<br />
                        8. Finance amount, tenure, interest rate and approval of finance is sole description of financier.<br />
                    </p>
                </div>
                <div class="payment-terms mt-3">
                    <h3>Payment Terms:</h3>
                    <p>
                        1. The Buyer shall pay the sale price in full by Payment Method, e.g., cash, bank transfer / With Finance.<br />
                        2. The payment shall be made on or before  Date:<b class="text-red"> {{Vehicle.PaymentDueDate  | date:'dd-MM-yyyy'}} </b>.<br />
                    </p>
                    <h3 class="mt-3">Finance Details:</h3>

                    <div class="row">
                        <div class="col-lg-4"> 1. Total Cost of Vehicle  </div>
                        <div class="col-lg-8">Rs.<b class="text-red">{{Vehicle.VehiclePrice}}/-</b>. </div>
                        <div class="col-lg-4"> 2. Buyer Agree to pay </div>
                        <div class="col-lg-8">Rs.<b class="text-red">{{Vehicle.Agreetopay}}/-</b>. </div>
                        <div class="col-lg-4"> 3. Remaining amount financed</div>
                        <div class="col-lg-8">Rs.<b class="text-red">{{Vehicle.RemainingAmount}}/-</b>. </div>
                        <div class="col-lg-4"> 4. Name of the Financier:</div>
                        <div class="col-lg-8"><b class="text-red">{{Vehicle.FinancierName}}</b>. </div>
                        <div class="col-lg-4"> 5. Address:</div>
                        <div class="col-lg-8"><b class="text-red"> {{Vehicle.FinancierAddress}}</b>. </div>
                    </div>


                </div>

                <div class="payment-details" style="position: relative">
                    <h3 class="mt-3">Payment Details:</h3>
                    <p>
                        Cash Received Advanced Rs.<b class="text-red">{{Vehicle.PaidAmount}}</b>/- Due amount Rs.<b class="text-red">{{Vehicle.RemainingAmount}}</b>/- Pending Due date: <b class="text-red">{{Vehicle.PaymentDueDate  | date:'dd-MM-yyyy'}}</b>
                    </p>
                </div>

                <div class="buyer-details-Signature">
                    <h3 *ngIF="Docstore">Documents available with: <b class="text-red">{{Vehicle.Docstore}}</b></h3>

                    <h3 class="mt-5">Buyer Signature:</h3>
                    <span style="text-align:end; position:absolute; right:20px; margin-top: 2px;">P.T.O</span>
                </div>

                
               
                <br />
                <br />

                <div class="sale-terms " *ngIf="isGeneratingPDF">
                    <div class="">
                        <h1 style="font-size: 3px; text-align:center; font-family: Arial Black; text-decoration: none;">
                          
                            <!--<b>{{loginDet[0].CompanyName | uppercase}}</b>-->
                        </h1>
                        <p style="text-align:center; line-height: 1.2rem">

                            <!--<b>{{loginDet[0].CompanyAddress}}</b>, <b> {{loginDet[0].Mobile}}</b>-->
                        </p>
                    </div>
                </div>

                <!--<div class="sale-terms " *ngIf="isGeneratingPDF">
                    <div class="pdf-header mt-3">
                        <div class="topheader">
                            <h1 style="font-size: 27px; text-align:center; font-family: Arial Black; text-decoration: none;">
                                <b>{{loginDet[0].CompanyName}}</b>
                            </h1>

                        </div>
                    </div>
                </div>-->
                <!-- Part 2 should start from the next page -->
                <br />
                <br />
                <div class="Delivery-Note sale-terms mt-1">
                    <h3 style="text-align:center; ">PART-<span style=" font-weight: 500; font-family: 'Poppins';"><b>&#8545;</b></span> Delivery Note</h3>
                </div>

                <div class="TRANSFER-OF-OWNERSHIP mt-3">
                    <h3>Transfer of Ownership:</h3>
                    <p>
                        1. The Seller shall transfer the ownership of the vehicle to the Buyer upon receipt of the sale price.<br />
                        2. The Buyer shall be responsible for registering the vehicle in their name.
                    </p>
                </div>
                <div class="WARRANTIES-AND-REPRESENTATIONS mt-3">
                    <h3>Warranties and Representations:</h3>
                    <p>
                        1. The Seller warrants that the vehicle is free from any defects or liabilities.<br />
                        2. The Buyer acknowledges that they have inspected the vehicle and are satisfied with its present condition.
                    </p>
                </div>
                <div class="DISPUTE-RESOLUTION mt-3">
                    <h3><b>Dispute Resolution:</b></h3>
                    <p>Once the vehicle is sold, there will be no more opportunities for any disputes.</p>
                </div>
                <div class="ACKNOWLEDGMENT mt-3">
                    <h3><b>Acknowledgment:</b></h3>
                    <p>
                        By signing below, both parties acknowledge that they have read, understand, and agree to the terms and conditions of this Agreement, and the buyer has received the vehicle.
                    </p>
                </div>

                <div class="pb-5"></div>
                <div class="last mb-5">
                    <div class="column">
                        <h3 class="ms-3">Seller's Sign</h3>

                    </div>

                    <div class="column">
                        <h3 class="ms-3">Buyer's Sign</h3>

                    </div>

                    <div class="column">
                        <h3 class="ms-3">Witness's Sign 1</h3>

                    </div>

                    <div class="column">
                        <h3 class="ms-3">Witness's Sign 2</h3>

                    </div>

                </div>

                <div class="last ">
                    <div class="column">
                        <h3 class="ms-3  mb-2">Seller Image</h3>
                        <div class="box">
                            <img [src]="sellerImageUrl" alt="pic" *ngIf="sellerImageUrl; else defaultSellerImage" />
                            <ng-template #defaultSellerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3  mb-2">Buyer Image</h3>
                        <div class="box">
                            <img [src]="buyerImageUrl" alt="pic" *ngIf="buyerImageUrl; else defaultBuyerImage" />
                            <ng-template #defaultBuyerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3  mb-2">Witness Image</h3>
                        <div class="box">
                            <img [src]="witnessImageUrl" alt="pic" *ngIf="witnessImageUrl; else defaultWitnessImage" />
                            <ng-template #defaultWitnessImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3  mb-2">Witness Image2</h3>
                        <div class="box">
                            <img [src]="WitnessImage2Url" alt="pic" *ngIf="WitnessImage2Url; else defaultVehicleImage" />
                            <ng-template #defaultVehicleImage>

                            </ng-template>
                        </div>
                    </div>

                </div>

                <div class="last mt-4">

                    <div class="column ">
                        <h3 class="ms-3  mb-2">Vehicle Front</h3>
                        <div class="box">
                            <img [src]="sellerSignatureUrl" alt="pic" *ngIf="sellerSignatureUrl; else defaultSellerImage" />
                            <ng-template #defaultSellerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3  mb-2">Vehicle Back</h3>
                        <div class="box">
                            <img [src]="buyerSignatureUrl" alt="pic" *ngIf="buyerSignatureUrl; else defaultBuyerImage" />
                            <ng-template #defaultBuyerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3  mb-2" >Vehicle Left</h3>
                        <div class="box">
                            <img [src]="witnessSignatureUrl" alt="pic" *ngIf="witnessSignatureUrl; else defaultWitnessImage" />
                            <ng-template #defaultWitnessImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3  mb-2">Vehicle Right</h3>
                        <div class="box">
                            <img [src]="vehiclePhotoUrl" alt="pic" *ngIf="vehiclePhotoUrl; else defaultVehicleImage" />
                            <ng-template #defaultVehicleImage>

                            </ng-template>
                        </div>
                    </div>

                </div><br />

                <p>
                     Final Receipt:  Total Amount of Rs.<b class="text-red">{{Vehicle.VehiclePrice}}</b>/-&nbsp;&nbsp;

                </p><br />
                <br />
                <span> Seller Signature</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 "></div>
        <div class="col-lg-1 " *ngIf="isGeneratingbutton">
            <button (click)="navigateTOeditform( )" class="pdf"><i class="fa fa-edit"></i> Edit</button>

        </div>
        <div class="col-lg-3" *ngIf="isGeneratingbutton">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF</button>
        </div>
    </div>

</div>

<style>
    .text-red{
        color:red !important;
    }
</style>
