
<div id="pdf-content" class="pt-100">

    <div class="container mt-4">

        <!-- Next Page Header -->

        <div class="pdf-header">
            <div class="topheader">
                <h1 style="font-size: 25px; text-align:center; font-family: Arial Black; text-decoration: none;margin-top:40px;">
                    <b>{{loginDet[0].CompanyName | uppercase}}</b>
                </h1>
                <h3 style="text-align: center;font-size: 16px;">
                    {{loginDet[0].CompanyAddress}}
                </h3>
                <p style="text-align: center; line-height: 1.2rem; font-size: 16px;margin-bottom:10px;">
                     {{loginDet[0].Mobile}}
                </p>
            </div>
        </div>



        <div class="row">
            <div class="col-lg-12">
                <h1 style="text-align: center; font-size: 18px;margin-top:15px;">PURCHASE RECEIPT / AGREEMENT</h1>
                <h1 style="text-align: center; font-size: 16px;">On Commision Basis</h1>
                <p class="float-end" style="font-size: 14px;">Date: <span style="color:red">{{currentDate}}</span></p><br />
            </div>
        </div>




        <div class="row">

            <div class="col-lg-12 ">
                <div class="seller-details">
                    <h3><b>Buyer Details:</b></h3>

                    <div class="row">
                        <div class="col-lg-12 lh_1p8">
                            <span style="color:red">{{buyer.BuyerName}}</span> S/o
                            <span style="color:red">{{buyer.BuyerFatherName}} </span>,
                            <span style="color:red">{{buyer.BuyerAge}}yrs </span>,<span style="color:red">{{loginDet[0].CompanyName}}</span>
                            <span style="color:red">{{loginDet[0].CompanyAddress}}</span>, 
                            <span style="color:red">{{buyer.BuyerOccupation}}</span>,
                            <span style="color:red">{{buyer.BuyerHNo}},{{buyer.BuyerStreet}}, {{buyer.BuyerColony}}, {{buyer.BuyerMandal}}, {{buyer.BuyerState}}, {{buyer.buyerPincode}}</span>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12" style="margin-top:20px;">
                <div class="buyer-details">
                    <h3><b>Seller Details:</b></h3>

                    <div class="col-lg-12 lh_1p8">
                        <span style="color:red">{{seller.SellerName}}</span> S/o
                        <span style="color:red">{{seller.SellerFatherName}} </span>,
                        <span style="color:red">{{seller.SellerAge}}yrs </span>,
                        <span style="color:red">{{seller.SellerOccupation}} </span>,
                        <!--{{seller.SellerName}} s/o  {{seller.SellerFatherName}}, age - {{seller.SellerAge}}, {{seller.SellerOccupation}},-->
                        <span style="color:red">{{seller.SellerHNo}},{{seller.SellerStreet}}, {{seller.SellerColony}}, {{seller.SellerMandal}}, {{seller.SellerState}}, {{seller.SellerPincode}}</span>
                    </div>


                </div>
            </div>
        </div>
       <br />
        <div class="vehicle-details">
            <h3 style="font-size:16px;">VEHICLE DETAILS: Transport/NonTransport: {{Vehicle.TransportType}}</h3>
            <div class="row">
                <!--<div class="col-lg-12">
    <table class="table">
        <tbody>
            <tr *ngIf="Vehicle.VehicleRegistrationNo || Vehicle.VehicleChassisNo || Vehicle.VehicleEngineNo">
                <td class="border" *ngIf="Vehicle.VehicleRegistrationNo">Registration No</td>
                <td class="border" *ngIf="Vehicle.VehicleRegistrationNo"><b>{{Vehicle.VehicleRegistrationNo}}</b></td>
                <td class="border widthh"></td>
                <td class="border" *ngIf="Vehicle.VehicleChassisNo">Chassis No</td>
                <td class="border" *ngIf="Vehicle.VehicleChassisNo"><b>{{Vehicle.VehicleChassisNo}}</b></td>
                <td class="border widthh"></td>
                <td class="border" *ngIf="Vehicle.VehicleEngineNo">Engine No</td>
                <td class="border" *ngIf="Vehicle.VehicleEngineNo"><b>{{Vehicle.VehicleEngineNo}}</b></td>
            </tr>

            <tr *ngIf="Vehicle.TransportType || Vehicle.VehicleType || Vehicle.VehicleModel">-->
                <!--<td class="border" *ngIf="Vehicle.TransportType">TRANSPORT/NON TRANSPORT</td>
    <td class="border" *ngIf="Vehicle.TransportType"><b>{{Vehicle.TransportType}}</b></td>-->
                <!--<td class="border widthh"></td>
                    <td class="border" *ngIf="Vehicle.VehicleType">Class of Vehicle</td>
                    <td class="border" *ngIf="Vehicle.VehicleType"><b>{{Vehicle.VehicleType}}</b></td>
                    <td class="border widthh"></td>
                    <td class="border" *ngIf="Vehicle.VehicleTypes"> Vehicle Name</td>
                    <td class="border" *ngIf="Vehicle.VehicleTypes"><b>{{Vehicle.VehicleTypes}}</b></td>
                    <td class="border widthh"></td>
                    <td class="border" *ngIf="Vehicle.VehicleMake">Manufacturer Name</td>
                    <td class="border" *ngIf="Vehicle.VehicleMake"><b>{{Vehicle.VehicleMake}}</b></td>


                </tr>

                <tr *ngIf="Vehicle.VehicleFuelType || Vehicle.RCValidity">
                    <td class="border" *ngIf="Vehicle.VehicleModel">Vehicle MODEL</td>
                    <td class="border" *ngIf="Vehicle.VehicleModel"><b>{{Vehicle.VehicleModel}}</b></td>
                    <td class="border widthh"></td>
                    <td class="border" *ngIf="Vehicle.VehicleFuelType">FUEL</td>
                    <td class="border" *ngIf="Vehicle.VehicleFuelType"><b>{{Vehicle.VehicleFuelType}}</b></td>
                    <td class="border widthh"></td>
                    <td class="border" *ngIf="Vehicle.RCValidity">RC Validity</td>
                    <td class="border" *ngIf="Vehicle.RCValidity"><b>{{Vehicle.RCValidity | date:'dd-MM-yyyy'}}</b></td>
                </tr>
            </tbody>
        </table>
    </div>-->
                <div class="col-lg-12">
                    <table class="table table-bordered" style="font-size:13px;">
                        <tbody>
                            <!-- First Row -->
                            <tr *ngIf="Vehicle.VehicleRegistrationNo || Vehicle.VehicleChassisNo || Vehicle.VehicleEngineNo">
                                <td *ngIf="Vehicle.VehicleRegistrationNo">
                                    <b>Registration No:</b> {{Vehicle.VehicleRegistrationNo}}
                                </td>
                                <td *ngIf="Vehicle.VehicleChassisNo">
                                    <b>Chassis No:</b> {{Vehicle.VehicleChassisNo}}
                                </td>
                                <td *ngIf="Vehicle.VehicleEngineNo">
                                    <b>Engine No:</b> {{Vehicle.VehicleEngineNo}}
                                </td>
                            </tr>

                            <!-- Second Row -->
                            <tr *ngIf="Vehicle.VehicleType || Vehicle.VehicleTypes || Vehicle.VehicleMake">
                                <td *ngIf="Vehicle.VehicleType">
                                    <b>Vehicle Type:</b> {{Vehicle.VehicleType}}
                                </td>
                                <td *ngIf="Vehicle.VehicleTypes">
                                    <b>Vehicle Name:</b> {{Vehicle.VehicleTypes}}
                                </td>
                                <td *ngIf="Vehicle.VehicleMake">
                                    <b>Class of Vehicle:</b> {{Vehicle.VehicleMake}}
                                </td>
                            </tr>

                            <!-- Third Row -->
                            <tr *ngIf="Vehicle.VehicleModel || Vehicle.VehicleFuelType || Vehicle.RCValidity">
                                <td *ngIf="Vehicle.VehicleModel">
                                    <b>Vehicle Model:</b> {{Vehicle.VehicleModel}}
                                </td>
                                <td *ngIf="Vehicle.VehicleFuelType">
                                    <b>Fuel:</b> {{Vehicle.VehicleFuelType}}
                                </td>
                                <td *ngIf="Vehicle.RCValidity">
                                    <b>RC Validity:</b> {{Vehicle.RCValidity | date:'dd-MM-yyyy'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!--<div class="row">
        <div class="col-lg-12">
            <h3>DOCUMENTS HISTORY:</h3><br />
        </div>
        <div class="col-lg-12">
            <table class="table">
                <tbody>
                    <tr *ngIf="Vehicle.FitnessValidity || Vehicle.PermitNumber || Vehicle.PermitValidity">
                        <td class="border" *ngIf="Vehicle.FitnessValidity">FITNESS VALIDITY</td>
                        <td class="border" *ngIf="Vehicle.FitnessValidity"><b>{{Vehicle.FitnessValidity | date:'dd-MM-yyyy'}}</b></td>
                        <td class="border widthh"></td>
                        <td class="border" *ngIf="Vehicle.PermitNumber">PERMIT No</td>
                        <td class="border" *ngIf="Vehicle.PermitNumber"><b>{{Vehicle.PermitNumber}}</b></td>
                        <td class="border widthh"></td>
                        <td class="border" *ngIf="Vehicle.PermitValidity">Permit Validity</td>
                        <td class="border" *ngIf="Vehicle.PermitValidity"><b>{{Vehicle.PermitValidity | date:'dd-MM-yyyy'}}</b></td>
                    </tr>

                    <tr *ngIf="Vehicle.MeterSealValidity || Vehicle.CNGLPGValidity || Vehicle.EVBatteryValidity">
                        <td class="border" *ngIf="Vehicle.MeterSealValidity">METER SEAL VALIDITY</td>
                        <td class="border" *ngIf="Vehicle.MeterSealValidity"><b>{{Vehicle.MeterSealValidity | date:'dd-MM-yyyy'}}</b></td>
                        <td class="border widthh"></td>
                        <td class="border" *ngIf="Vehicle.CNGLPGValidity">CNG/LPG TANK VALIDITY</td>
                        <td class="border" *ngIf="Vehicle.CNGLPGValidity"><b>{{Vehicle.CNGLPGValidity | date:'dd-MM-yyyy'}}</b></td>
                        <td class="border widthh"></td>
                        <td class="border" *ngIf="Vehicle.EVBatteryValidity">E.V. BATTERY WARRANTY</td>
                        <td class="border" *ngIf="Vehicle.EVBatteryValidity"><b>{{Vehicle.EVBatteryValidity | date:'dd-MM-yyyy'}}</b></td>
                    </tr>

                    <tr *ngIf="Vehicle.TaxValidity">
                        <td class="border" *ngIf="Vehicle.TaxValidity">TAX VALIDITY</td>
                        <td class="border" *ngIf="Vehicle.TaxValidity"><b>{{Vehicle.TaxValidity | date:'dd-MM-yyyy'}}</b></td>
                        <td class="border" *ngIf="Vehicle.InsuranceValidity">INSURANCE VALIDITY</td>
                        <td class="border" *ngIf="Vehicle.InsuranceValidity"><b>{{Vehicle.InsuranceValidity | date:'dd-MM-yyyy'}}</b></td>
                        <td class="border widthh"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>-->
                <div class="row">
                    
                        <h3 style="font-size:16px;">DOCUMENTS HISTORY:</h3><br />
                    
                    <div class="col-lg-12">
                        <table class="table table-bordered">
                            <tbody>
                                <!-- First Row -->
                                <tr *ngIf="Vehicle.FitnessValidity || Vehicle.PermitNumber || Vehicle.PermitValidity">
                                    <td *ngIf="Vehicle.FitnessValidity">
                                        <b>Fitness Validity:</b> {{Vehicle.FitnessValidity | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td *ngIf="Vehicle.PermitNumber">
                                        <b>Permit No:</b> {{Vehicle.PermitNumber}}
                                    </td>
                                    <td *ngIf="Vehicle.PermitValidity">
                                        <b>Permit Validity:</b> {{Vehicle.PermitValidity | date:'dd-MM-yyyy'}}
                                    </td>
                                </tr>

                                <!-- Second Row -->
                                <tr *ngIf="Vehicle.MeterSealValidity || Vehicle.CNGLPGValidity || Vehicle.EVBatteryValidity">
                                    <td *ngIf="Vehicle.MeterSealValidity">
                                        <b>Meter Seal Validity:</b> {{Vehicle.MeterSealValidity | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td *ngIf="Vehicle.CNGLPGValidity">
                                        <b>CNG/LPG Tank Validity:</b> {{Vehicle.CNGLPGValidity | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td *ngIf="Vehicle.EVBatteryValidity">
                                        <b>E.V. Battery Warranty:</b> {{Vehicle.EVBatteryValidity | date:'dd-MM-yyyy'}}
                                    </td>
                                </tr>

                                <!-- Third Row -->
                                <tr *ngIf="Vehicle.TaxValidity || Vehicle.InsuranceValidity">
                                    <td *ngIf="Vehicle.TaxValidity">
                                        <b>Tax Validity:</b> {{Vehicle.TaxValidity | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td *ngIf="Vehicle.InsuranceValidity">
                                        <b>Insurance Validity:</b> {{Vehicle.InsuranceValidity | date:'dd-MM-yyyy'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class=" sale-terms ">
                    <h3 class="mt-3">Sale Terms:</h3>
                    <p style="font-size: 13px;">
                        1. The seller confirms that the vehicle is free from any finance or loan obligations/with finance.<br />
                        2.	The seller will come to the RTO office physically if required for the vehicle transfer.<br />
                        3. The buyer acknowledges that the vehicle is being purchased in its current condition
                    </p>
                    <h3 class="mt-3"> Additional declarations:</h3>
                    <p style="font-size: 13px;">
                        1. The seller has provided all necessary documents and information .<br />
                        2. The seller warrants that the vehicle is free from any RTA, police, traffic costs, or civil and
                        criminal cases.<br />
                        3.	The buyer has inspected the vehicle and is satisfied with its condition.
                    </p>
                    <span style="text-align:end; position:absolute; right:20px;font-size: 9px;" class="mt-5 mb-n3">P.T.O</span><br />
                </div>

            </div>
          
            <!--<div class="mt-5 "></div>-->
            <div  *ngIf="isGeneratingPDF" style="page-break-before: always;">
                <div class="pb-2"> </div>
                <div class="pdf-header mt-5" style="font-size: 15px; text-align:center; font-family: Arial Black; font-weight: 600;text-decoration: none;margin-top:25px;">
                    <!--<div class="topheader">
                        <h1 style="font-size: 40px; text-align:center; font-family: Arial Black; text-decoration: none;">
                            <b>{{loginDet[0].CompanyName | uppercase}}</b>
                        </h1>
                        <p style="text-align:center; line-height: 1.2rem">
                            <b>{{loginDet[0].CompanyAddress}}</b><br />
                            <b>{{loginDet[0].Mobile}}</b>
                        </p>
                    </div>-->
                    PART-2 Delivery Note
                </div>
            </div>


            <!--<div class="additional-decorations">
        <h3> Additional declarations:</h3>
        <p> 1.	The seller has provided all necessary documents and information </p>
        <p> 2.	The seller will come to the RTO office physically if required for the vehicle transfer </p>
        <p> 3.	The buyer has inspected the vehicle and is satisfied with its condition </p>
    </div>-->
            <div class="Finance_Details">
                <h3> Finance Details: </h3>

                <!--<div class="row">
        <div class="col-lg-9 mt-2 ">VehicleRate: </div>
        <div class="col-lg-3 mt-2 "> <b>{{ Vehicle.VehicleRate | number:'1.2-2' }}</b> </div>
        <div class="col-lg-9 mt-2 ">Fitness Charges: </div>
        <div class="col-lg-3 mt-2 "><b>{{Vehicle.FitnessCharges | number:'1.2-2'}}</b></div>

        <div class="col-lg-9 mt-2 ">Permit Charges: </div>
        <div class="col-lg-3 mt-2 "><b>{{Vehicle.PermitCharges | number:'1.2-2'}}</b></div>
        <div class="col-lg-9 mt-2 ">EChallan Charges: </div>
        <div class="col-lg-3 mt-2 "><b>{{Vehicle.EChallanCharges | number:'1.2-2'}}</b></div>
        <div class="col-lg-9 mt-2 ">Insurance Charges: </div>
        <div class="col-lg-3 mt-2 "><b>{{Vehicle.InsuranceCharges | number:'1.2-2'}}</b></div>
        <div class="col-lg-9 mt-2 ">Finance Amount: </div>
        <div class="col-lg-3 mt-2 "><b>{{Vehicle.FinanceAmount | number:'1.2-2'}}</b></div>
        <div class="col-lg-9 mt-2 ">Bal Amnt Sale Consideration: </div>
        <div class="col-lg-3 mt-2 "><b>{{Vehicle.BalAmntSaleConsideration | number:'1.2-2'}}</b></div>
        <div class="col-lg-9 mt-2 ">Paid To Seller: </div>
        <div class="col-lg-3 mt-2 "><b>{{Vehicle.PaidToSeller | number:'1.2-2'}}</b></div>

        <div class="col-lg-9 mt-2 ">Bal Amnt To PaidSeller:</div>
        <div class="col-lg-3 mt-2 ">  <b>{{Vehicle.BalAmntToPaidSeller | number:'1.2-2'}}</b></div>-->
                <!--<div class="col-lg-9 mt-2 ">4. Interest/Late Payment:</div>
    <div class="col-lg-3 mt-2 "> </div>-->
                <!--</div>-->

                <div class="col-lg-12">
                   
                        <div class="table">
                            <!-- Row 1 -->
                            <div class="row" style="margin-bottom:10px;">
                                <div class="col-lg-4" style="font-size:15px;">Description</div>
                                <div class="col-lg-4" style="font-size:15px;">Charges(₹)</div>
                                <div class="col-lg-4" style="font-size:15px;">Total Amount(₹)</div>

                            </div>
                            <!-- Row 2 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Vehicle Rate:</div>
                                <div class="col-lg-4"></div>
                                <div class="col-lg-4">{{ Vehicle.VehicleRate | number:'1.2-2' }}</div>
                                <!--<div class="col-lg-4"><b>{{ Vehicle.FitnessCharges | number:'1.2-2' }}</b></div>-->
                            </div>
                            <!-- Row 3 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Fitness Charges:</div>
                                <div class="col-lg-4">{{ Vehicle.FitnessCharges | number:'1.2-2' }}</div>
                                <div class="col-lg-4"></div>

                            </div>
                            <!-- Row 4 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Permit Charges:</div>
                                <div class="col-lg-4">{{ Vehicle.PermitCharges | number:'1.2-2' }}</div>
                                <div class="col-lg-4"></div>

                            </div>
                            <!-- Row 5 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">E Challan Charges:</div>
                                <div class="col-lg-4">{{ Vehicle.EChallanCharges | number:'1.2-2' }}</div>
                                <div class="col-lg-4"></div>
                            </div>
                            <!-- Row 6 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Insurance Charges:</div>
                                <div class="col-lg-4">{{ Vehicle.InsuranceCharges | number:'1.2-2' }}</div>
                                <div class="col-lg-4"></div>
                            </div>
                            <!-- Row 7 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Finance Amount:</div>
                                <div class="col-lg-4">{{ Vehicle.FinanceAmount | number:'1.2-2' }}</div>
                                <div class="col-lg-4"></div>
                            </div>
                            <!-- Row 8 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Bal Amount Sale Consideration:</div>
                                <div class="col-lg-4"></div>
                                <div class="col-lg-4">{{ Vehicle.BalAmntSaleConsideration | number:'1.2-2' }}</div>
                            </div>
                            <!-- Row 8 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Paid To Seller:</div>
                                <div class="col-lg-4">{{ Vehicle.PaidToSeller | number:'1.2-2' }}</div>
                                <div class="col-lg-4"></div>
                            </div>
                            <!-- Row 10 -->
                            <div class="row lh-lg">
                                <div class="col-lg-4">Bal Amount To Paid Seller:</div>
                                <div class="col-lg-4"></div>
                                <div class="col-lg-4">{{ Vehicle.BalAmntToPaidSeller | number:'1.2-2' }}</div>
                            </div>
                        </div>
                   
                </div>
                <div class=" Sale-Consideration" style="margin-top:35px;">

                    <h3>Sale Consideration:</h3>
                    <p>
                        The Buyer Pruchased Vehicle From Seller For <b>{{Vehicle.VehicleRate | number:'1.2-2'}}</b>
                    </p>
                </div>
                <br /> <br />

                <div class="pb-5"></div>
                <div class="pb-5"></div>
                <div class="last">
                    <div class="column">
                        <h3 class="ms-3">Seller's Sign</h3>

                    </div>

                    <div class="column">
                        <h3 class="ms-3">Buyer's Sign</h3>

                    </div>

                    <div class="column">
                        <h3 class="ms-3">Witness's Sign 1</h3>

                    </div>

                    <div class="column">
                        <h3 class="ms-3">Witness's Sign 2</h3>

                    </div>


                </div>

                <div class="last">
                    <div class="column">
                        <h3 class="ms-3">Seller Image</h3>
                        <div class="box">
                            <img [src]="sellerImageUrl" alt="pic" *ngIf="sellerImageUrl; else defaultSellerImage" />
                            <ng-template #defaultSellerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3">Buyer Image</h3>
                        <div class="box">
                            <img [src]="buyerImageUrl" alt="pic" *ngIf="buyerImageUrl; else defaultBuyerImage" />
                            <ng-template #defaultBuyerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3">Witness Image</h3>
                        <div class="box">
                            <img [src]="witnessImageUrl" alt="pic" *ngIf="witnessImageUrl; else defaultWitnessImage" />
                            <ng-template #defaultWitnessImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3">Witness Image2</h3>
                        <div class="box">
                            <img [src]="WitnessImage2Url" alt="pic" *ngIf="WitnessImage2Url; else defaultVehicleImage" />
                            <ng-template #defaultVehicleImage>

                            </ng-template>
                        </div>
                    </div>


                </div>


                <div class="last">
                    <div class="column">
                        <h3 class="ms-3">Vehicle Front</h3>
                        <div class="box">
                            <img [src]="sellerSignatureUrl" alt="pic" *ngIf="sellerSignatureUrl; else defaultSellerImage" />
                            <ng-template #defaultSellerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3">Vehicle Back</h3>
                        <div class="box">
                            <img [src]="buyerSignatureUrl" alt="pic" *ngIf="buyerSignatureUrl; else defaultBuyerImage" />
                            <ng-template #defaultBuyerImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3">Vehicle Left</h3>
                        <div class="box">
                            <img [src]="witnessSignatureUrl" alt="pic" *ngIf="witnessSignatureUrl; else defaultWitnessImage" />
                            <ng-template #defaultWitnessImage>

                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3 class="ms-3">Vehicle Right</h3>
                        <div class="box">
                            <img [src]="vehiclePhotoUrl" alt="pic" *ngIf="vehiclePhotoUrl; else defaultVehicleImage" />
                            <ng-template #defaultVehicleImage>

                            </ng-template>
                        </div>
                    </div>


                </div>

            </div>

    </div>

    <div class="row">
        <div class="col-lg-8 "></div>
        <div class="col-lg-1 " *ngIf="isGeneratingbutton">
            <button (click)="navigateTOeditform(Fdata[0].RefNo )" class="pdf"><i class="fa fa-edit"></i> Edit</button>
        </div>
        <div class="col-lg-3" *ngIf="isGeneratingbutton">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF</button>
        </div>
    </div>
</div>

