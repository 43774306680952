import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-buyform',

    templateUrl: './buyform.component.html',
    styleUrl: './buyform.component.scss'
})
export class BuyformComponent {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    vehicleForm: FormGroup;
    sellForm: FormGroup;
    buyForm: FormGroup;
    RegisterForm: FormGroup;
    signForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementNo: any
    isUpdateMode: boolean = false;
    // items: MenuItem[] | undefined;
    activeIndex: number = 0;

    ImageData: any;
    image: any;
    EImage: any;
    Exteriorimg: any;
    ImageArray: any;
    maxImages: any;
    BuyerSignature: any;
    WitnessSignature: any;
    VehiclePhoto: any;
    active: number = 0;
    SellerIMG: any; BuyyerIMG: any; WitnessIMG1: any; WitnessIMG2: any;

    currentStep: number = 1;
    //isTransport: boolean = false; // Determines if "Transport" is selected
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute,
        //  public messageService: MessageService
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.sellForm = this.formBuilder.group({
            SellerName: ['', [Validators.required]],
            SellerFatherName: ['', [Validators.required]],
            SellerAge: ['', [Validators.required]],
            SellerOccupation: ['', [Validators.required]],
            SellerHNo: ['', [Validators.required]],
            SellerStreet: ['', [Validators.required]],
            SellerColony: ['', [Validators.required]],
            SellerMandal: ['', [Validators.required]],
            SellerState: ['', [Validators.required]],
            SellerPincode: ['', [Validators.required]],
            SellerMobile: ['', [Validators.required]],
        });
        this.buyForm = this.formBuilder.group({
            BuyerName: [this.loginDet[0].Name || '', [Validators.required]],
            BuyerFatherName: [this.loginDet[0].FatherName || '', [Validators.required]],
            BuyerAge: [this.loginDet[0].Age || '', [Validators.required]],
            BuyerOccupation: ['', [Validators.required]],
            BuyerHNo: [this.loginDet[0].HNo || '', [Validators.required]],
            BuyerStreet: [this.loginDet[0].Street || '', [Validators.required]],
            BuyerColony: [this.loginDet[0].Colony || '', [Validators.required]],
            BuyerMandal: [this.loginDet[0].Mandal || '', [Validators.required]],
            BuyerState: [this.loginDet[0].StateName || '', [Validators.required]],
            BuyerPincode: [this.loginDet[0].Pincode || '', [Validators.required]],
            BuyerMobile: [this.loginDet[0].Mobile || '', [Validators.required]],
        });
        this.vehicleForm = this.formBuilder.group({
            VehicleRegistrationNo: ['', [Validators.required]],
            VehicleChassisNo: ['', [Validators.required]],
            VehicleEngineNo: ['', [Validators.required]],
            VehicleType: ['', [Validators.required]],
            TransportType: ['', [Validators.required]],
            VehicleModel: ['', [Validators.required]],
            VehicleFuelType: ['', [Validators.required]],
            FitnessValidity: ['', [Validators.required]],
            PermitNumber: ['', [Validators.required]],
            PermitValidity: ['', [Validators.required]],
            MeterSealValidity: ['', [Validators.required]],
            CNGLPGValidity: ['', [Validators.required]],
            EVBatteryValidity: ['', [Validators.required]],
            TaxValidity: ['', [Validators.required]],
            RCValidity: ['', [Validators.required]],
            PaymentDueDate: ['', [Validators.required]],
            VehicleRate: [0, [Validators.required]],  
            FitnessCharges: [0, [Validators.required]],
            PermitCharges: [0, [Validators.required]], 
            EChallanCharges: [0, [Validators.required]],
            InsuranceCharges: [0, [Validators.required]],
            FinanceAmount: [0, [Validators.required]], 
            BalAmntSaleConsideration: [0, [Validators.required]],
            PaidToSeller: [0, [Validators.required]],
            BalAmntToPaidSeller: [0, [Validators.required]],
            //VehiclePrice: ['', [Validators.required]],
            //PaidAmount: ['', [Validators.required]],
            //RemainingAmount: ['', [Validators.required]],
            //FinancierName: ['', [Validators.required]],
            //FinancierAddress: ['', [Validators.required]],
            VehicleTypes: ['', [Validators.required]],


            SellerIMG: ['', [Validators.required]],
            BuyyerIMG: ['', [Validators.required]],
            WitnessIMG1: ['', [Validators.required]],
            WitnessIMG2: ['', [Validators.required]],

            InsuranceValidity: ['', [Validators.required]],
            VehicleMake: ['', [Validators.required]],
            SellerSignature: ['', [Validators.required]],
            BuyerSignature: ['', [Validators.required]],
            WitnessSignature: ['', [Validators.required]],
            VehiclePhoto: ['', [Validators.required]],
        });
        this.calculateBalances();

       

            this.aggrementNo = localStorage.getItem("AgreementNo");
        }
    

    ngOnInit() {
       
    }

    onActiveIndexChange(event: number) {
        this.activeIndex = event;
    }
    goToNext() {
        debugger
    
        if (this.currentStep === 2) {
            // Validate that all fields in the buyer form are filled
            if (this.sellForm.invalid) {
                alert('Please fill in all required fields.');
                return; // Stop if validation fails
            }

            // Save buyer details to localStorage
            const sellerData = this.sellForm.value;  // Assuming buyForm is your form for buyer details
            localStorage.setItem('seller', JSON.stringify(sellerData));
            // console.log('Buyer details saved:', buyerData);
        }

        if (this.currentStep === 1) {
            // Validate that all fields in the buyer form are filled
            if (this.buyForm.invalid) {
                alert('Please fill in all required fields.');
                return; // Stop if validation fails
            }

            // Save buyer details to localStorage
            const buyerData = this.buyForm.value;  // Assuming buyForm is your form for buyer details
            localStorage.setItem('buyer', JSON.stringify(buyerData));
            // console.log('Buyer details saved:', buyerData);
        }

        if (this.currentStep === 3) {
            // Save buyer details to localStorage
            const vehicleData = this.vehicleForm.value;  // Assuming buyerForm is your form for buyer details
            localStorage.setItem('Vehicle', JSON.stringify(vehicleData));
            //  console.log('Buyer details saved:', buyerData);
        } if (this.currentStep === 4) {
            // Save buyer details to localStorage
            const vehicleData = this.vehicleForm.value;  // Assuming buyerForm is your form for buyer details
            localStorage.setItem('Vehicle', JSON.stringify(vehicleData));
            //  console.log('Buyer details saved:', buyerData);
        }
        if (this.currentStep < 5) {
            const PaymentDetails = this.vehicleForm.value;  // Assuming buyerForm is your form for buyer details
            localStorage.setItem('Payment', JSON.stringify(PaymentDetails));
            this.currentStep++;
        }
    }

    calculateBalances(): void {
        debugger
        const vehicleRate = +this.vehicleForm.get('VehicleRate')?.value || 0;
        const fitnessCharges = +this.vehicleForm.get('FitnessCharges')?.value || 0;
        const permitCharges = +this.vehicleForm.get('PermitCharges')?.value || 0;
        const eChallanCharges = +this.vehicleForm.get('EChallanCharges')?.value || 0;
        const insuranceCharges = +this.vehicleForm.get('InsuranceCharges')?.value || 0;
        const financeAmount = +this.vehicleForm.get('FinanceAmount')?.value || 0;
        const paidToSeller = this.vehicleForm.get('PaidToSeller')?.value || 0;

        console.log('vehicleRate:', vehicleRate, 'fitnessCharges:', fitnessCharges);

        const BalAmntSaleConsideration = vehicleRate - (fitnessCharges + permitCharges + eChallanCharges + insuranceCharges + financeAmount);
        console.log('balAmntSaleConsideration:', BalAmntSaleConsideration);

        this.vehicleForm.get('BalAmntSaleConsideration')?.setValue(BalAmntSaleConsideration);
        // Calculate BalAmntToPaidSeller
        const BalAmntToPaidSeller = BalAmntSaleConsideration - paidToSeller;
        this.vehicleForm.get('BalAmntToPaidSeller')?.setValue(BalAmntToPaidSeller);
    }


    page() {
        this.router.navigate(['/Buyerform'])
    }

    goToPrevious() {
        if (this.currentStep > 1) {
            this.currentStep--;
        }
    }



    storeSellerData() {
        const sellerData = this.RegisterForm.value;
        localStorage.setItem('buyer', JSON.stringify(sellerData));
    }

    getRegistration(formValue: any) {

        // Store the data in localStorage
        this.storeSellerData();
    }

    updateRegistration(formValue: any) {

        this.storeSellerData();
    }
    removeImage1(index: number): void {
        this.Exteriorimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage(index: number): void {
        this.BuyerSignature.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage2(index: number): void {
        this.WitnessSignature.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage3(index: number): void {
        this.VehiclePhoto.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage4(index: number): void {
        this.SellerIMG.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage5(index: number): void {
        this.BuyyerIMG.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage6(index: number): void {
        this.WitnessIMG1.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage7(index: number): void {
        this.WitnessIMG2.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }

    detectFiles(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.Exteriorimg = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_exterior', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles1(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.BuyerSignature = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_buyer', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles2(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.WitnessSignature = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_witness', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles3(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.VehiclePhoto = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_vehicle', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles4(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.SellerIMG = [e.target.result]; // Replace with the new image
                localStorage.setItem('Seller_photo', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }


    detectFiles5(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.BuyyerIMG = [e.target.result]; // Replace with the new image
                localStorage.setItem('Buyyer_photo', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles6(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.WitnessIMG1 = [e.target.result]; // Replace with the new image
                localStorage.setItem('Witness_photo1', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles7(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.WitnessIMG2 = [e.target.result]; // Replace with the new image
                localStorage.setItem('Witness_photo2', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }


    //This is for Uploading Multiple Images
    fileChange(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                if (data != null) {
                    this.ImageData = data;
                    this.image = data;
                    this.EImage.push({ ExteriorImages: this.ImageData })
                }
            });
        }

    }


    submitVehicleDetails(formValue: any) {
        console.log('Vehicle Form Submitted:', formValue);
        // Additional logic for vehicle form submission
    }

    updateVehicleDetails(formValue: any) {
        console.log('Vehicle Form Updated:', formValue);
        // Additional logic for vehicle form update
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    onDateChange(event: any) {
        const formattedDate = this.formatDate(event.target.value);
        this.RegisterForm.get('AuctionedDate')?.setValue(formattedDate);
    }
    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }


    //onTransportTypeChange(): void {
    //    const transportType = this.vehicleForm.get('TransportType')?.value;
    //    this.isTransport = transportType === 'Transport';
    //}


    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }

    // Method to fetch states from the server
    getstates() {
        debugger;
        var url = "api/FinancierLaw/FA_GetStates";
        this.generalService.Getdata(url).then(
            data => {
                debugger;
                this.Statedata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a state is selected in the dropdown
    onStateSelection(value: string) {
        debugger;
        this.StateId = value;  // Store the selected state ID
        this.Getdistricts();  // Fetch districts based on the selected state
    }

    // Method to fetch districts based on the selected state ID
    Getdistricts() {
        debugger;
        if (!this.StateId) {
            this.generalService.ShowAlert('ERROR', 'State ID is missing. Please select a state.', 'error');
            return;
        }

        var UploadFile = new FormData();
        UploadFile.append("StateId", this.StateId);  // Append the state ID to the form data

        var url = "api/FinancierLaw/FA_GetDistricts";
        this.generalService.Postdata1(url, UploadFile).subscribe(
            data => {
                debugger;
                this.DistrictName = data;  // Store the fetched district data
                if (!this.DistrictName || this.DistrictName.length === 0) {
                    this.generalService.ShowAlert('ERROR', 'No districts found for the selected state.', 'error');
                }
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a district is selected in the dropdown
    onDistrictSelection(value: string) {
        debugger;
        this.selectedDistrictName = value;  // Store the selected district name
    }


}


